<template>
    <div class="page-footer" v-if="Object.keys(generalContent).length">
        <div class="wrapper">
            <div class="footer-item info">
                <img src="./../assets/images/logo-white.svg">
                <p class="description" v-html="generalContent.footerInfoText[lang]"></p>
            </div>
            <nav class="footer-item nav">
                <p class="nav-title">{{ pageTexts.pages[lang] }}</p>
                <ul class="menu-list">
                    <li class="menu-item"><router-link to="/">{{ generalContent.menu.home[lang] }}</router-link></li>
                    <li class="menu-item"><router-link to="/about" href="">{{ generalContent.menu.about[lang] }}</router-link></li>
                    <li class="menu-item"><a href="https://drive.google.com/drive/folders/1wqSQ1gGVAF8SGrTqlbYv02K5dmlHDo44?usp=drive_link" target="_blank">{{ pageTexts.downloads[lang] }}</a></li>
                    <!-- <li class="menu-item"><router-link to="/categories" href="">{{ generalContent.menu.catalog[lang] }}</router-link></li> -->
                    <!-- <li class="menu-item"><router-link to="/faq">{{ generalContent.menu.faq[lang] }}</router-link></li> -->
                    <li class="menu-item" v-if="generalContent.menu.services[lang].length"><router-link to="/services">{{ generalContent.menu.services[lang] }}</router-link></li>
                    <li class="menu-item" v-if="generalContent.menu.warranty[lang].length"><router-link to="/warranty">{{ generalContent.menu.warranty[lang] }}</router-link></li>
                    <li class="menu-item" v-if="generalContent.menu.delivery[lang].length"><router-link to="/delivery">{{ generalContent.menu.delivery[lang] }}</router-link></li>
                    <li class="menu-item" v-if="generalContent.menu.sertificates[lang].length"><router-link to="/sertificates">{{ generalContent.menu.sertificates[lang] }}</router-link></li>
                    <li class="menu-item"><router-link to="/contacts">{{ generalContent.menu.contacts[lang] }}</router-link></li>
                </ul>
            </nav>
            <nav class="footer-item nav hidden-xs visible-md">
                <p class="nav-title">{{ pageTexts.categories[lang] }}</p>
                <ul class="menu-list">
                    <li class="menu-item" v-for="category in categories" :key="category.id">
                        <router-link :to="{ name: 'products', params: { id: category.id, categoryInfo: category } }" class="c-link">{{ category.name[lang] }}</router-link>
                    </li>
                </ul>
            </nav>
            <div class="footer-item contacts">
                <p class="nav-title">{{ pageTexts.contacts[lang] }}</p>
                <div class="contact">
                    <p class="title">{{ generalContent.header.phoneTitle[lang] }}</p>
                    <p class="value">{{ generalContent.header.phone }}</p>
                    <p class="description">{{ generalContent.header.phoneDescription[lang] }}</p>
                </div>
                <div class="contact" v-for="(contact, index) in contactsPageContent.contactsList[0].list" :key="index">
                    <p class="title">{{ contactsPageContent.contactsList[0].sectionTitle[lang] }}</p>
                    <p class="value">{{ contact.email }}</p>
                    <p class="description">{{ contact.title[lang] }}</p>
                </div>
            </div>
        </div>
        <div class="bottom-line">
            <div class="wrapper">
                <div class="copyright">
                    <p>{{ generalContent.copyrightText[lang] }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageTexts: {
                    pages: {
                        ua: 'Сторінки',
                        ru: 'Страницы',
                        en: 'Pages'
                    },
                    categories: {
                        ua: 'Категорії',
                        ru: 'Категории',
                        en: 'Categories'
                    },
                    contacts: {
                        ua: 'Контакти',
                        ru: 'Контакты',
                        en: 'Contacts'
                    },
                    downloads: {
                        ua: 'Завантаження',
                        ru: 'Загрузки',
                        en: 'Downloads'
                    }
                }
            }
        },
        computed: {
            contactsPageContent() {
                return this.$store.getters['getContactsPageContent'];
            },
            generalContent() {
                return this.$store.getters['getGeneralPageContent'];
            },
            categories() {
                return this.$store.getters['getCategories'];
            },    
            lang() {
                return this.$store.getters['getSelectedLang'];
            }
        }
    }
</script>
