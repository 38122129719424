<template>
    <div class="page-header" v-if="Object.keys(generalContent).length">
        <div class="top-line" :class="{'collapse': isHeaderCollapse}">
            <div class="wrapper">
                <div class="logo">
                    <a href="/"><img src="./../assets/images/logo.svg"></a>
                </div>
                <nav class="menu hidden-xs visible-md">
                    <ul class="menu-list">
                        <li class="menu-item"><router-link to="/">{{ generalContent.menu.home[lang] }}</router-link></li>
                        <li class="menu-item"><router-link to="/about" href="">{{ generalContent.menu.about[lang] }}</router-link></li>
                        <li class="menu-item" v-if="generalContent.menu.services[lang].length"><router-link to="/services">{{ generalContent.menu.services[lang] }}</router-link></li>
                        <li class="menu-item"><router-link to="/contacts">{{ generalContent.menu.contacts[lang] }}</router-link></li>
                    </ul>
                </nav>
                <div class="search-field hidden-md visible-xs" :class="{active: isMobileSearchOpen}">
                    <input type="text" id="pageHeaderSearchField" class="searchField" :placeholder="generalContent.headerSearchFieldText[lang]" v-model="query">
                    <button class="close-btn" @click="toggleMobileSearch()">
                        <span class="fas fa-times"></span>
                    </button>
                    <div class="search-result" :class="{'active': isSearchResultVisible}">
                        <div class="list">
                            <div class="item" v-for="(product, i) in filteredProducts" :key="i">
                                <router-link :to="{ name: 'product', params: { categoryId: product.category, productId: product.code } }" class="item-link"></router-link>
                                <img :src="product.image" class="image">
                                <div class="info">
                                    <p class="p-title">{{ product.name[lang] }}</p>
                                    <p class="price">{{ product.cost }} UAH</p>
                                </div>
                            </div>
                        </div>
                        <p class="msg" v-if="!filteredProducts.length">{{ pageText.emptySearchMsg[lang] }}</p>
                    </div>
                </div>
                <div class="actions">
                    <div class="contacts hidden-xs visible-sm" v-if="!isHeaderCollapse">
                        <a :href="'tel:' + generalContent.header.phone" class="contact">
                            <span class="icon fas fa-phone-alt"></span>
                            <div class="content">
                                <span class="title">{{ generalContent.header.phoneTitle[lang] }}</span>
                                <span class="value">{{ generalContent.header.phone }}</span>
                                <p class="description">{{ generalContent.header.phoneDescription[lang] }}</p>
                            </div>
                        </a>
                    </div>
                    <div class="actions-btn" v-if="isHeaderCollapse">
                        <button class="action visible-xs hidden-md" @click="toggleMobileSearch()">
                            <span class="icon fas fa-search"></span>
                        </button>
                        <router-link to="/favorites" class="action">
                            <span class="icon fas fa-heart"></span>
                            <span class="badge">{{ productsInFavorites.length }}</span>
                        </router-link>
                        <router-link to="/cart" class="action">
                            <span class="icon fas fa-shopping-cart"></span>
                            <span class="badge">{{ productsInCart.length }}</span>
                        </router-link>
                    </div>
                    <div class="catalog-btn hidden-xs visible-md" v-if="isHeaderCollapse">
                        <router-link class="c-drop-btn" to="/categories">
                            <span class="icon fas fa-list"></span>
                            <span class="text hidden-xs visible-sm">{{ generalContent.menu.catalog[lang] }}</span>
                        </router-link>
                        <div class="drop-list">
                            <ul>
                                <li v-for="(category, index) in categories" :key="index">
                                    <router-link :to="{ name: 'products', params: { id: category.id, categoryInfo: category } }" class="c-link">
                                        {{ category.name[lang] }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="lang-switcher hidden-xs visible-md" :class="{'active': isLangMenuOpen}"> 
                        <div class="s-btn" @click="toggleLangMenu()">
                            <span class="text">{{ lang }}</span>
                            <span class="icon fas fa-chevron-down"></span>
                        </div>
                        <div class="s-drop-menu">
                            <ul>
                                <li @click="changeLang('ua'), closeLangMenu()">ua</li>
                                <li @click="changeLang('ru'), closeLangMenu()">ru</li>
                                <li @click="changeLang('en'), closeLangMenu()">en</li>
                            </ul>
                        </div>
                    </div>
                    <div class="mobile-menu-trigger visible-xs hidden-md">
                        <div class="mm-btn" @click="isMobileMenuOpen = true">
                            <span class="icon fas fa-bars"></span>
                        </div>
                        <div class="mm-drop" :class="{'active': isMobileMenuOpen}">
                            <button class="mm-close" @click="isMobileMenuOpen = false">
                                <span class="fas fa-times"></span>
                            </button>
                            <ul class="mm-list">
                                <li class="mm-item"><router-link to="/">{{ generalContent.menu.home[lang] }}</router-link></li>
                                <li class="mm-item"><router-link to="/about" href="">{{ generalContent.menu.about[lang] }}</router-link></li>
                                <li class="mm-item"><router-link to="/categories" href="">{{ generalContent.menu.catalog[lang] }}</router-link></li>
                                <li class="mm-item" v-if="generalContent.menu.services[lang].length"><router-link to="/services">{{ generalContent.menu.services[lang] }}</router-link></li>
                                <li class="mm-item" v-if="generalContent.menu.warranty[lang].length"><router-link to="/warranty">{{ generalContent.menu.warranty[lang] }}</router-link></li>
                                <li class="mm-item" v-if="generalContent.menu.delivery[lang].length"><router-link to="/delivery">{{ generalContent.menu.delivery[lang] }}</router-link></li>
                                <li class="mm-item" v-if="generalContent.menu.sertificates[lang].length"><router-link to="/sertificates">{{ generalContent.menu.sertificates[lang] }}</router-link></li>
                                <li class="mm-item"><router-link to="/contacts">{{ generalContent.menu.contacts[lang] }}</router-link></li>
                            </ul>
                            <div class="lang-switcher">
                                <button @click="changeLang('ua')" :class="{active: lang == 'ua'}">ua</button>
                                <button @click="changeLang('ru')" :class="{active: lang == 'ru'}">ru</button>
                                <button @click="changeLang('en')" :class="{active: lang == 'en'}">en</button>
                            </div>
                            <div class="contacts">
                                <a :href="'tel:' + generalContent.header.phone" class="contact">
                                    <span class="title">{{ generalContent.header.phoneTitle[lang] }}</span>
                                    <span class="value">{{ generalContent.header.phone }}</span>
                                    <p class="description">{{ generalContent.header.phoneDescription[lang] }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-line hidden-xs visible-md">
            <div class="wrapper">
                <div class="catalog-btn" :key="dropBtnKey">
                    <router-link class="c-drop-btn" to="/categories">
                        <span class="icon fas fa-list"></span>
                        <span class="text hidden-xs visible-sm">{{ generalContent.menu.catalog[lang] }}</span>
                    </router-link>
                    <div class="drop-list" ref="dropList">
                        <ul>
                            <li v-for="(category, index) in categories" :key="index">
                                <router-link :to="{ name: 'products', params: { id: category.id, categoryInfo: category } }" class="c-link">
                                    {{ category.name[lang] }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="search-field">
                    <input type="text" class="searchField" :placeholder="generalContent.headerSearchFieldText[lang]" v-model="query">
                    <div class="search-result" :class="{'active': isSearchResultVisible}">
                        <div class="list" v-if="filteredProducts.length">
                            <div class="item" v-for="(product, i) in filteredProducts" :key="i">
                                <router-link :to="{ name: 'product', params: { categoryId: product.category, productId: product.code } }" class="item-link"></router-link>
                                <img :src="product.image" class="image">
                                <div class="info">
                                    <p class="p-title">{{ product.name[lang] }}</p>
                                    <p class="price">{{ product.cost }} UAH</p>
                                </div>
                            </div>
                        </div>
                        <p class="msg" v-else>{{ pageText.emptySearchMsg[lang] }}</p>
                    </div>
                </div>
                <div class="actions-btn hidden-xs visible-flex-sm">
                    <router-link to="/favorites" class="action">
                        <span class="icon fas fa-heart"></span>
                        <span class="badge">{{ productsInFavorites.length }}</span>
                    </router-link>
                    <router-link to="/cart" class="action">
                        <span class="icon fas fa-shopping-cart"></span>
                        <span class="badge">{{ productsInCart.length }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isMobileSearchOpen: false,
                dropBtnKey: 0,
                isMobileMenuOpen: false,
                isSearchResultVisible: false,
                isHeaderCollapse: false,
                query: '',
                isLangMenuOpen: false,
                pageText: {
                    emptySearchMsg: {
                        ua: 'Вибачте, але ми не можемо знайти товари по Вашому запиту',
                        ru: 'Извините, но мы не можем найти товары по Вашему запросу',
                        en: 'Sorry, but we cant find any products by your request'
                    }
                }
            }
        },
        computed: {
            contactsPageContent() {
                return this.$store.getters['getContactsPageContent'];
            },
            generalContent() {
                return this.$store.getters['getGeneralPageContent'];
            },  
            lang() {
                return this.$store.getters['getSelectedLang'];
            },
            productsInCart() {
                return this.$store.getters['getProductsInCart'];
            },
            categories() {
                return this.$store.getters['getCategories'].filter(el => {
                    return (!el.isHidden);
                });
            },
            products() {
                return this.$store.getters['getProducts'];
            },
            filteredProducts() {
                return this.products.filter(el => {
                        return (!el.isDraft)
                            && ((this.query.length)
                                ? (~el.name['ua'].toLowerCase().indexOf(this.query.toLowerCase())
                                    || ~el.name['ru'].toLowerCase().indexOf(this.query.toLowerCase())
                                    || ~el.name['en'].toLowerCase().indexOf(this.query.toLowerCase()))
                                : true)
                    })
            },
            productsInFavorites() {
                return this.$store.getters['getProductsInFavorites'];
            }
        },
        watch: {
            lang() {
                this.dropBtnKey++;
            },
            '$route'(to) {
                this.isMobileSearchOpen = false;
                this.isMobileMenuOpen = false;
                if (to.name != 'categories') this.dropBtnKey++;
            }
        },
        methods: {
            toggleMobileSearch() {
                this.isMobileSearchOpen = !this.isMobileSearchOpen;
                setTimeout(() => {
                    document.querySelector('#pageHeaderSearchField').focus();
                }, 300);
            },
            changeLang(lang) {
                this.$store.dispatch('setLang', lang);
            },
            openLangMenu() {
                this.isLangMenuOpen = true;
            },
            closeLangMenu() {
                this.isLangMenuOpen = false;
            },
            toggleLangMenu() {
                this.isLangMenuOpen = !this.isLangMenuOpen;
            }
        },
        mounted() {
            if (window.innerWidth >= 900) {
                window.addEventListener('scroll', () => {
                    if (window.pageYOffset > 80) {
                        this.isHeaderCollapse = true;
                    } else {
                        this.isHeaderCollapse = false;
                    }
                });
            } else {
                this.isHeaderCollapse = true;
            }
            setTimeout(() => {
                document.querySelectorAll('.searchField').forEach(el => {
                    el.addEventListener('focus', () => {
                        this.isSearchResultVisible = true;
                    });
                })
                document.querySelectorAll('.searchField').forEach(el => {
                    el.addEventListener('input', () => {
                        if (this.query.length) this.isSearchResultVisible = true;
                    });
                })
                document.querySelectorAll('.searchField').forEach(el => {
                    el.addEventListener('blur', () => {
                        this.isSearchResultVisible = false;
                    });
                })
            }, 1000);
        }
    }
</script>
