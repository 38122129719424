import Vue from 'vue'
import Vuex from 'vuex'
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

Vue.use(Vuex)

const app = initializeApp({
    apiKey: "AIzaSyDlzljVoRVACnNTtII7DZ41bCRDWP-L2aI",
    authDomain: "luxeon-ea216.firebaseapp.com",
    projectId: "luxeon-ea216",
    storageBucket: "luxeon-ea216.appspot.com",
    messagingSenderId: "387904967917",
    appId: "1:387904967917:web:49da2b4aa94b26684828e4"
});

const NP_API_KEY = 'f265953f0cd2c5a1b30160cc58b86e68';
const NP_API = 'https://api.novaposhta.ua/v2.0/json/';

const Storage = getStorage();
const StorageListRef = ref(Storage, '/');
const DB = getFirestore(app);

function getColectionFromDatabase(c) {
	return getDocs(collection(DB, c));
}
function getDocFromDatabase(c, d) {
	return getDoc(doc(DB, c, d));
}
function setLS(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}
function getLS(key) {
	return JSON.parse(localStorage.getItem(key));
}
// function removeFromLs(key) {
// 	localStorage.removeItem(key);
// }

export default new Vuex.Store({
    namespaced: true,
    state: {
        selectedLang: 'ua',
        productsInCart: [],
        productsInFavorites: [],
        categories: [],
        products: [],
        imageStorage: [],
        metaInfo: {},
        avaliableLangs: ['ua', 'en', 'ru'],
        pagesContent: {
            home: '',
            about: '',
            catalog: '',
            faq: '',
            contacts: '',
            cart: '',
            favorites: '',
            services: '',
            warranty: '',
            delivery: '',
            sertificates: '',
            general: ''
        }
    },
    getters: {
        getMetaInfo(state) {
            return state.metaInfo;
        },
        getImageStorage(state) {
            return state.imageStorage;
        },
        getSelectedLang(state) {
            return state.selectedLang;
        },
        getProductsInCart(state) {
            return state.productsInCart;
        },
        getProductsInFavorites(state) {
            return state.productsInFavorites;
        },
        getCategories(state) {
            return state.categories.filter(el => {
                return el.isHidden === false;
            }).sort((a, b) => {
                if (parseFloat(a.order) > parseFloat(b.order)) return 1
                if (parseFloat(a.order) < parseFloat(b.order)) return -1
                return 0
            });
        },
        getProducts(state) {
            return state.products;
        },
        getHomePageContent(state) {
            return state.pagesContent.home;
        },
        getAboutPageContent(state) {
            return state.pagesContent.about;
        },
        getCatalogPageContent(state) {
            return state.pagesContent.catalog;
        },
        getFAQPageContent(state) {
            return state.pagesContent.faq;
        },
        getCartPageContent(state) {
            return state.pagesContent.cart;
        },
        getFavoritesPageContent(state) {
            return state.pagesContent.favorites;
        },
        getServicesPageContent(state) {
            return state.pagesContent.services;
        },
        getWarrantyPageContent(state) {
            return state.pagesContent.warranty;
        },
        getDeliveryPageContent(state) {
            return state.pagesContent.delivery;
        },
        getSertificatesPageContent(state) {
            return state.pagesContent.sertificates;
        },
        getContactsPageContent(state) {
            return state.pagesContent.contacts;
        },
        getGeneralPageContent(state) {
            return state.pagesContent.general;
        },
        getCategoryById: (state) => (id) => {
            return state.categories.filter((el) => {
                return (el.id == id);
            })[0];
        },
        getProductsByCategoryId: (state) => (id) => {
            return state.products.filter((el) => {
                return (el.category == id);
            });
        },
        getProductById: (state) => (id) => {
            return state.products.filter((el) => {
                return (el.code == id);
            })[0];
        },
        isProductInCart: (state) => (productId) => {
            return state.productsInCart.some((product) => {
                return (product.code == productId);
            });
        },
        isProductInFavorites: (state) => (productId) => {
            return state.productsInFavorites.some((product) => {
                return (product == productId);
            });
        }
    },
    mutations: {
    },
    actions: {
        getDepartmentsFromNP(context, params) {
            return fetch(NP_API, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    apiKey: NP_API_KEY,
                    modelName: "AddressGeneral",
                    calledMethod: "getWarehouses",
                    methodProperties: {
                        CityRef: params.cityRef,
                        Language : "UA",
                        FindByString: params.id
                    }
                })
            });
        }, 
        getCitiesFromNP(context, cityName) {
            return fetch(NP_API, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    apiKey: NP_API_KEY,
                    modelName: "AddressGeneral",
                    calledMethod: "searchSettlements",
                    methodProperties: {
                        CityName: cityName,
                    }
                })
            })
        },
        setLang(context, lang) {
            if (~context.state.avaliableLangs.indexOf(lang)) {
                context.state.selectedLang = lang;
                localStorage.setItem('lang', lang);
            }
        },
        fetchMetaInfo(context) {
            getDocFromDatabase('SEO', 'Meta').then((data) => {
				context.state.metaInfo = data.data();
			});
        },
        fetchCategories(context) {
			getColectionFromDatabase('Categories').then((data) => {
				data.forEach((element) => {
					context.state.categories.push(element.data());
				})
			});
		},
        fetchProducts(context) {
			getColectionFromDatabase('Products').then((data) => {
				data.forEach((element) => {
					context.state.isCatalogLoading = false;
					context.state.products.push(element.data());
				})
			});
		},
        fetchStaticContent(context) {
			getDocFromDatabase('Pages', 'Home').then((data) => {
				context.state.pagesContent.home = data.data();
			});
			getDocFromDatabase('Pages', 'Catalog').then((data) => {
				context.state.pagesContent.catalog = data.data();
			});
			getDocFromDatabase('Pages', 'Cart').then((data) => {
				context.state.pagesContent.cart = data.data();
			});
			getDocFromDatabase('Pages', 'Favorites').then((data) => {
				context.state.pagesContent.favorites = data.data();
			});
			getDocFromDatabase('Pages', 'Services').then((data) => {
				context.state.pagesContent.services = data.data();
			});
			getDocFromDatabase('Pages', 'Warranty').then((data) => {
				context.state.pagesContent.warranty = data.data();
			});
			getDocFromDatabase('Pages', 'Delivery').then((data) => {
				context.state.pagesContent.delivery = data.data();
			});
			getDocFromDatabase('Pages', 'Sertificates').then((data) => {
				context.state.pagesContent.sertificates = data.data();
			});
			getDocFromDatabase('Pages', 'About').then((data) => {
				context.state.pagesContent.about = data.data();
			});
			getDocFromDatabase('Pages', 'Contacts').then((data) => {
				context.state.pagesContent.contacts = data.data();
			});
			getDocFromDatabase('Pages', 'FAQ').then((data) => {
				context.state.pagesContent.faq = data.data();
			});
			getDocFromDatabase('Pages', 'GeneralInfo').then((data) => {
				context.state.pagesContent.general = data.data();
			});
		},
        fetchProductsInFavorites(context) {
            if (getLS('productsInFavorites')) {
                context.state.productsInFavorites = getLS('productsInFavorites');
            }
        },
        fetchImageStorage(context) {
            listAll(StorageListRef)
                .then((res) => {
                    let storage = new Array(res.prefixes.length + 1);
                    let i = -1;
                    res.prefixes.forEach(prefixe => {
                        i++;
                        storage[i] = {
                            name: prefixe.name,
                            list: []
                        }
                        listAll(prefixe)
                            .then(prefixeRes => {
                                prefixeRes.items.forEach(image => {
                                    getDownloadURL(image)
                                        .then(url => {
                                            storage[i].list.push({
                                                name: image._location.path.split('/')[1],
                                                url: url
                                            });
                                        });
                                });
                            })
                    });
                    storage[i+1] = {
                        name: 'Other',
                        list: []
                    }
                    res.items.forEach((image) => {
                        getDownloadURL(image)
                            .then(url => {
                                storage[i + 1].list.push({
                                    name: image._location.path,
                                    url: url
                                });
                            });
                    });
                    context.state.imageStorage = storage;
                });
        },
        addToFavorites(context, productCode) {
            let isProductInFavorites = this.getters['isProductInFavorites'](productCode);
            if (!isProductInFavorites) {
                context.state.productsInFavorites.push(productCode);
                setLS('productsInFavorites', context.state.productsInFavorites);
            }
        },
        removeProductFromFavorites(context, index) {
            context.state.productsInFavorites.splice(index, 1);
            setLS('productsInFavorites', context.state.productsInFavorites);
        },
        fetchProductsInCart(context) {
            if (getLS('productsInCart')) {
                context.state.productsInCart = getLS('productsInCart');
            }
        },
        addToCart(context, productInfo) {
            let isProductInCart = this.getters['isProductInCart'](productInfo.code);
            if (!isProductInCart) {
                context.state.productsInCart.push(productInfo);
                setLS('productsInCart', context.state.productsInCart);
            }
        },
        replaceProductsInCart(context, products) {
            context.state.productsInCart = products;
            setLS('productsInCart', context.state.productsInCart);
        },
        removeProductFromCart(context, index) {
            context.state.productsInCart.splice(index, 1);
            setLS('productsInCart', context.state.productsInCart);
        },
        sendCartOrder(context, order) {
            return setDoc(doc(DB, 'Orders', order.docName), order);
        }
    },
    modules: {
    }
});
