import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/CategoriesView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/ContactsView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FAQView.vue')
  },
  {
    path: '/categories/:id',
    name: 'products',
    component: () => import('../views/ProductsView.vue')
  },
  {
    path: '/categories/:categoryId/:productId',
    name: 'product',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/CartView.vue')
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import('../views/FavoritesView.vue')
  },
  {
    path: '/image-storage',
    name: 'imageStorage',
    component: () => import('../views/ImageStorageView.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/ServicesView.vue')
  },
  {
    path: '/warranty',
    name: 'warranty',
    component: () => import('../views/WarrantyView.vue')
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('../views/DeliveryView.vue')
  },
  {
    path: '/sertificates',
    name: 'sertificates',
    component: () => import('../views/SertificatesView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('../views/NotFoundView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0};
	}
});

router.beforeEach((to, from, next) => {
  next();
  if (router.app.$store) {
      let meta = router.app.$store.getters['getMetaInfo'];
      var product = '';
      if (to.name == 'product') {
        product = router.app.$store.getters['getProductById'](to.params.productId).name.ua;
      }
      let target = to.name;
      document.querySelector('title').innerText = product + meta.pages[target].title;
      document.head.querySelector('meta[name="description"]').setAttribute('content', meta.pages[target].description);
      document.head.querySelector('meta[name="keywords"]').setAttribute('content', meta.pages[target].keywords);
  }
})

export default router
